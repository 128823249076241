import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Breadcrumb from '../component-elements/breadcrumb';
import CmsMulticolumnBrand from '../brands-component/content-elements/CmsMulticolumnBrand';
import ProductGrid from '../components/shop-category/product-grid';
import ContentHeaderCms from '../content-elements/contentHeaderCms';

const BrandCollectionDetailTemplate = ({
  data: {
    datoCmsProductCollection: productCollection,
  },
  pageContext: {
    basePath,
    lang,
    brandMagentoId,
    pages,
    page,
  },
  location,
}) => {
  const breadcrumbParentPath = [
    { id: brandMagentoId.toString(), name: productCollection.brand.name, url: productCollection.brand.fullUrlPath },
    {
      id: `${brandMagentoId}-collection`,
      name: t`breadcrumb_brand_colletion_item_label`,
      url: productCollection.brand.collectionsUrlPath,
    },
  ];

  return (
    <Layout textcolorHeader="black" textcolorHeaderMobile="black">
      <Breadcrumb
        items={[
          ...breadcrumbParentPath,
          { id: productCollection.id, name: productCollection.name },
        ]}
      />

      <ContentHeaderCms>
        <h1>{productCollection.name}</h1>
      </ContentHeaderCms>

      <ProductGrid
        staticFilters={[{
          key: 'jr_brand',
          values: [brandMagentoId],
        }, {
          key: 'jr_article_line',
          values: [productCollection.magentoId],
        }]}
        initialPageCount={pages}
        initialPage={page}
        items={productCollection.magentoProducts}
        lang={lang}
        location={location}
        initialItemTotal={productCollection.magentoProductsCount}
        breadcrumb={[
          ...breadcrumbParentPath,
          {
            id: productCollection.id,
            name: productCollection.name,
            url: basePath,
          },
        ]}
      />

      {productCollection?.content.map((content) => (
        <CmsMulticolumnBrand key={content.id} {...content} />
      ))}

      <Helmet
        tags={productCollection.seoMetaTags}
        metaRobots={productCollection.metaRobots}
        metaCanonical={productCollection.metaCanonical}
        title={productCollection.seo?.title}
        titleFallback={t('brand_collection_detail_seo_title_fallback', productCollection.name, productCollection.brand.name)}
        description={productCollection.seo?.description}
        descriptionFallback={t('brand_collection_detail_seo_description_fallback', productCollection.name, productCollection.brand.name)}
        url={basePath}
      />
    </Layout>
  );
};

export default BrandCollectionDetailTemplate;

BrandCollectionDetailTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
query brandCollectionDetailQuery($url: String, $lang: String, $brandMagentoId: Int, $limit: Int, $skip: Int) {
    datoCmsProductCollection(url: {eq: $url}, locale: {eq: $lang}) {
        id
        name
        brand {
            name
            fullUrlPath
            collectionsUrlPath
        }
        metaCanonical
        metaRobots
        seoMetaTags {
            tags
        }
        seo {
            title
            description
        }
        content {
            ...ce_multicolumn_text
        }
        magentoId
        magentoProductsCount(brandMagentoId: $brandMagentoId)
        magentoProducts(brandMagentoId: $brandMagentoId, limit: $limit, skip: $skip) {
            ...shopProductListItem
        }
    }
}
`;
